import { atom } from 'recoil';

export interface ICheckoutFormData {
    firstName: string;
    lastName: string;
    university: string;
    fieldOfStudy: string;
    comment?: string;
    street: string;
    address2?: string;
    postalCode: string;
    city: string;
    country: string;
    birthDate: string;
    phone: string;
    email: string;
    emailConfirmation: string;
    cityOfBirth: string;
    countryOfBirth: string;
    nationality: string;
    identification: File[];
    maritalStatus: string;
    files: File[];
    noFiles?: boolean;
    gender: string;
    petDescription?: string;
    lang?: string;
    roomMate?: string;
    roomMateFirstName?: string;
    roomMateLastName?: string;
    petWanted?: boolean;
}

const defaultValues = {
    firstName: '',
    lastName: '',
    university: '',
    fieldOfStudy: '',
    comment: '',
    street: '',
    postalCode: '',
    city: '',
    country: '',
    phone: '',
    email: '',
    emailConfirmation: '',
    cityOfBirth: '',
    countryOfBirth: '',
    nationality: '',
    identification: [],
    maritalStatus: '',
    birthDate: '',
    files: [],
    noFiles: false,
    gender: '',
    roomMate: '',
    petWanted: false,
};

export const checkoutFormDataState = atom<ICheckoutFormData | null>({
    key: 'checkoutFormData',
    default: defaultValues,
});

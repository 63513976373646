import React from 'react';
import styles from './LoadingPage.module.scss';

interface Props {}

const LoadingPage: React.FunctionComponent<Props> = () => {
    return (
        <div className={styles.wrapper}>
            <div>
                <img
                    className="logo"
                    src="/logo-claim-black.png"
                    alt="STUWO"
                    width="300"
                />
                <div className={styles.loader}>Loading...</div>
            </div>
        </div>
    );
};

export default LoadingPage;

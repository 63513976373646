import css from 'classnames';
import React from 'react';
import styles from './Footer.module.scss';

interface Props {}

const Header: React.FunctionComponent<Props> = () => {
    return (
        <footer className={styles.footer}>
            <div className={css(styles.grid, 'o-content')}>
                <div className={styles.columnOne}></div>
                <div className={styles.columnTwo}></div>
                <div className={styles.columnThree}>
                    <img
                        src="/logo-weiss.png"
                        alt="STUWO"
                        className={styles.footer__logo}
                    />
                    <div className={styles.contact}>
                        <p>
                            Gemeinnützige{' '}
                            <span className={styles.contact__nobreak}>
                                Studentenwohnbau AG
                            </span>
                        </p>
                        <p>+43 1 40493 618</p>
                        <p>
                            E-Mail:{' '}
                            <a href="mailto:info@stuwo.at">info@stuwo.at</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Header;

declare global {
    interface Window {
        dataLayer: any[];
    }
}

export const GTM_TRACKING_ID = process.env.REACT_APP_GTM_ID;

export const gtmEvent = (
    eventName: string,
    payload: Record<string, string | number | boolean>,
): void => {
    if (Array.isArray(window.dataLayer)) {
        window.dataLayer.push({
            event: eventName,
            ...payload,
        });
    }
};

interface GetDatePickerHeaderProps {
    years: number[];
    months: string[];
}

interface DatePickerHeaderProps {
    monthDate: Date;
    date: Date;
    changeYear(year: number): void;
    changeMonth(month: number): void;
    customHeaderCount: number;
    decreaseMonth(): void;
    increaseMonth(): void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
    decreaseYear(): void;
    increaseYear(): void;
    prevYearButtonDisabled: boolean;
    nextYearButtonDisabled: boolean;
}

export const getDatePickerHeader = ({
    years,
    months,
}: GetDatePickerHeaderProps) => {
    return ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }: DatePickerHeaderProps): React.ReactNode => {
        const selectedYear = date.getFullYear();
        const selectedMonth = date.getMonth();

        return (
            <div className="react-datepicker-ovl-header">
                <button
                    className="react-datepicker-ovl-header__prev"
                    type="button"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                >
                    {'◄'}
                </button>

                <select
                    className="react-datepicker-ovl-header__select"
                    value={selectedYear}
                    onChange={(event) =>
                        changeYear(parseInt(event.target.value))
                    }
                >
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <select
                    className="react-datepicker-ovl-header__select"
                    value={months[selectedMonth]}
                    onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                    }
                >
                    {months.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <button
                    className="react-datepicker-ovl-header__next"
                    type="button"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                >
                    {'►'}
                </button>
            </div>
        );
    };
};

import { put } from '.';
import { IFetcherResponse } from '../models';

export const deregistration = async (
    personNo: string,
    email: string,
): Promise<IFetcherResponse<any>> => {
    if (!personNo || !email) {
        Promise.reject('no data for deregistration');
    }

    return put(`${process.env.REACT_APP_BOOKING_API_URL}/deregistration`, {
        personNo,
        email,
    });
};

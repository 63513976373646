import { Revalidator } from 'swr';
import { IFetcherError, IFetcherResponse } from '../models';

export const fetcher = async (url: string): Promise<IFetcherResponse<any>> => {
    const res = await fetch(url);

    if (!res.ok) {
        const error: IFetcherError = new Error(
            'An error occurred while fetching the data.',
        );

        error.info = res.statusText;
        error.statusCode = res.status;

        throw error;
    }
    const contentType = res.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
        return {
            headers: Object.fromEntries(res.headers.entries()),
            body: await res.json(),
        };
    }
    if (contentType && contentType.includes('application/pdf')) {
        return {
            headers: Object.fromEntries(res.headers.entries()),
            body: await res.blob(),
        };
    }
    return {
        headers: Object.fromEntries(res.headers.entries()),
        body: res.text(),
    };
};

export const defaultOnErrorRetry = (
    err: any,
    key: string,
    config: any,
    revalidate: Revalidator,
    { retryCount }: any,
): void => {
    // Never retry on 404.
    if (err.statusCode === 404) return;
    // Only retry up to 3 times.
    if (retryCount >= 3) return;
    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount }), 5000);
};

import css from 'classnames';
import React, { useState } from 'react';
import {
    FieldError,
    RegisterOptions,
    UseFormRegister,
    UseFormRegisterReturn,
} from 'react-hook-form';
import fieldStyles from '../FormField.module.scss';

interface Props {
    name: string;
    label?: string;
    disabled?: boolean;
    error?: FieldError;
    className?: string;
    value?: string;
    defaultValue?: string | undefined;
    children?: JSX.Element | JSX.Element[];
    register?: UseFormRegister<any>;
    registerOptions?: RegisterOptions;
    onClick?: () => void;
    onChange?: (value: string) => void;
}

const SelectField: React.FunctionComponent<Props> = ({
    name,
    label,
    disabled,
    error,
    className,
    value,
    defaultValue,
    children,
    register,
    registerOptions,
    onClick,
    onChange,
}) => {
    let useFormProps: UseFormRegisterReturn | null = null;
    const [hasFocus, setFocus] = useState<boolean>(false);

    if (register) {
        useFormProps = register(name, Object.assign({}, registerOptions));
    }

    const handleOnBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
        setFocus(false);
        useFormProps && useFormProps.onBlur(event);
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange && onChange(event.target.value);
        useFormProps && useFormProps.onChange(event);
    };

    const nameAttr = useFormProps ? useFormProps.name : name;

    return (
        <div className={className} onClick={onClick}>
            {label?.length && (
                <label htmlFor={nameAttr} className={fieldStyles.label}>
                    {label}
                </label>
            )}

            <div
                className={css(fieldStyles.formField, {
                    [fieldStyles['-focus']]: hasFocus,
                    [fieldStyles['-error']]: !!error && !hasFocus,
                })}
            >
                <select
                    ref={useFormProps?.ref}
                    name={nameAttr}
                    value={value}
                    defaultValue={defaultValue}
                    onFocus={() => setFocus(true)}
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                    disabled={disabled}
                    className={fieldStyles.control}
                >
                    {children}
                </select>
            </div>

            {error?.message && (
                <small className={fieldStyles.error}>{error.message}</small>
            )}
        </div>
    );
};

export default SelectField;

import { lazy } from 'react';

const Home = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "home" */
            './home/Home'
        ),
);

const Dorm = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "dorm" */
            './dorm/Dorm'
        ),
);

const Room = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "room" */
            './room/Room'
        ),
);

const Checkout = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "checkout" */
            './checkout/Checkout'
        ),
);

const Thanks = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "thanks" */
            './thanks/Thanks'
        ),
);

const PaymentThanks = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "payment-thanks" */
            './payment-thanks/PaymentThanks'
        ),
);

const Payment = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "payment" */
            './payment/Payment'
        ),
);

const WaitingListDeregistration = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "payment" */
            './waiting-list-deregistration/WaitingListDeregistration'
        ),
);

const Login = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "login" */
            './login/Login'
        ),
);

const Password = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "password" */
            './password/Password'
        ),
);

const MembersArea = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "members-area" */
            './members-area/MembersArea'
        ),
);

const NotFound = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "not-found" */
            './not-found/NotFound'
        ),
);

export {
    Checkout,
    Dorm,
    Home,
    Login,
    MembersArea,
    NotFound,
    Password,
    Payment,
    PaymentThanks,
    Room,
    Thanks,
    WaitingListDeregistration,
};

import React from 'react';
import {
    FieldError,
    RegisterOptions,
    UseFormRegister,
    UseFormRegisterReturn,
} from 'react-hook-form';
import styles from './Checkbox.module.scss';

interface Props {
    name: string;
    label: string | React.ReactNode;
    error?: FieldError;
    className?: string;
    register?: UseFormRegister<any>;
    registerOptions?: RegisterOptions;
    onClick?: () => void;
    onChange?: (value: string) => void;
    defaultValue?: boolean | undefined;
}

const Checkbox: React.FunctionComponent<Props> = ({
    name,
    label,
    error,
    className,
    register,
    registerOptions,
    onClick,
    onChange,
    defaultValue,
}) => {
    let useFormProps: UseFormRegisterReturn | null = null;

    if (register) {
        useFormProps = register(name, Object.assign({}, registerOptions));
    }

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        useFormProps && useFormProps.onBlur(event);
    };

    const handleOnChange = (event: React.ChangeEvent<any>) => {
        onChange && onChange(event.target.checked);
        useFormProps && useFormProps.onChange(event);
    };

    const nameAttr = useFormProps ? useFormProps.name : name;

    return (
        <div className={className} onClick={onClick}>
            <label htmlFor={nameAttr} className={styles.checkbox}>
                <input
                    className={styles.control}
                    ref={useFormProps?.ref}
                    id={nameAttr}
                    name={nameAttr}
                    type="checkbox"
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                    defaultChecked={defaultValue}
                />
                <span className={styles.fake}></span>
                <span className={styles.label}>
                    <span>{label}</span>
                    {error?.message && (
                        <small className={styles.error}>{error.message}</small>
                    )}
                </span>
            </label>
        </div>
    );
};

export default Checkbox;

import css from 'classnames';
import React from 'react';
import { StatusType } from '../../shared';
import styles from './StatusMessage.module.scss';

interface Props {
    type: StatusType;
    children: JSX.Element | JSX.Element[];
    className?: string;
}

const StatusMessage: React.FunctionComponent<Props> = ({
    type,
    children,
    className,
}) => {
    return (
        <div
            className={css(styles.statusMessage, className, {
                [styles['-success']]: type === 'success',
                [styles['-warn']]: type === 'warn',
                [styles['-danger']]: type === 'danger',
            })}
        >
            {children}
        </div>
    );
};

export default StatusMessage;

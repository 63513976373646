import React from 'react';
import ErrorPage from './error';

interface Props {
    isLast?: boolean;
    children: React.ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage noTranslation={this.props.isLast} showButton />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

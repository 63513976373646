import { Box, Typography } from '@material-ui/core';
import css from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as UploadIcon } from '../../icons/upload.svg';
import { formatBytes } from '../../shared';
import styles from './FileUpload.module.scss';

interface Props {
    name: string;
    className?: string;
    allowFileTypes?: string[];
    hint?: string;
    error?: FieldError;
    onChange?: (value: File[]) => void;
    defaultValue?: File[] | undefined;
    single?: boolean;
}

const FileUpload: React.FunctionComponent<Props> = ({
    name,
    className,
    hint,
    error,
    allowFileTypes,
    onChange,
    defaultValue,
    single,
}) => {
    const { t } = useTranslation('common');
    const [files, setFiles] = useState<File[]>();

    const onDrop = useCallback(
        (acceptedFiles) => {
            console.log(acceptedFiles);
            setFiles(acceptedFiles);
            onChange && onChange(acceptedFiles);
        },
        [onChange],
    );

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: allowFileTypes,
        maxSize: 2097152, // 2MB
        ...(single && { maxFiles: 1, multiple: false }),
    });

    const [fileRejectionsState, setFileRejectionsState] =
        useState(fileRejections);

    useEffect(() => {
        if (defaultValue?.length) {
            setFiles(defaultValue);
        }
    }, [defaultValue]);

    const onDelete = (
        e: React.MouseEvent<HTMLButtonElement>,
        fileIdx: number,
    ) => {
        e.stopPropagation();

        if (!files) {
            return;
        }

        const updatedFiles = [
            ...files.slice(0, fileIdx),
            ...files.slice(fileIdx + 1),
        ];

        if (updatedFiles.length === 0) {
            setFileRejectionsState([]);
        }

        setFiles(updatedFiles);
        onChange && onChange(updatedFiles);
    };

    useEffect(() => {
        setFileRejectionsState(fileRejections);
    }, [fileRejections]);

    const fileListItems = useMemo(() => {
        return (files ?? []).map((file, idx) => {
            // Cut the filename length
            const fileNameMaxLength = 30;
            const fileNameSplit = file.name?.split('.');
            const fileExt = fileNameSplit?.[fileNameSplit?.length - 1];
            fileNameSplit?.pop();
            const fileNameNoExt = fileNameSplit?.join('.');
            const fileName =
                fileNameNoExt?.length > fileNameMaxLength
                    ? `${fileNameNoExt?.substring(
                          0,
                          fileNameMaxLength,
                      )} [...] .${fileExt}`
                    : `${fileNameNoExt?.substring(
                          0,
                          fileNameMaxLength,
                      )}.${fileExt}`;

            return (
                <li key={file?.name}>
                    <button type="button" onClick={(e) => onDelete(e, idx)}>
                        <DeleteIcon />
                    </button>
                    {fileName} <small> - {formatBytes(file?.size)}</small>
                </li>
            );
        });
    }, [files]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div
                {...getRootProps({
                    className: css(styles.fileUpload, className, {
                        [styles['-error']]: !!error,
                    }),
                })}
            >
                <input
                    {...getInputProps({
                        name,
                    })}
                />
                <div className={styles.wrapper}>
                    <div className={styles.icon}>
                        <UploadIcon />
                    </div>
                    <p>{t('fileUpload.dropFilesOrClick')}</p>
                </div>

                {fileListItems?.length > 0 && (
                    <ul className={styles.fileList}>{fileListItems}</ul>
                )}

                {fileRejectionsState.length > 0 && (
                    <Box
                        className={styles.errorList}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gridGap: 10,
                            mt: 2,
                        }}
                    >
                        {fileRejectionsState.map((item) => {
                            const file = item.file;
                            const { path, size }: any = file;

                            const errors: FileError[] = (item as FileRejection)
                                .errors;

                            return (
                                <Box
                                    component="li"
                                    key={path}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        color: 'error.main',
                                    }}
                                >
                                    <Typography variant="body2">
                                        {path} - {formatBytes(size)}
                                    </Typography>
                                    {errors.map((error) => (
                                        <Typography
                                            key={error.code}
                                            variant="caption"
                                        >
                                            {t(
                                                `checkout.formError.${error.code}`,

                                                {
                                                    maxSize: 2,
                                                    sizeUnit: 'MB',
                                                },
                                            )}
                                        </Typography>
                                    ))}
                                </Box>
                            );
                        })}
                    </Box>
                )}
            </div>

            {error && (
                <p className={css(styles.hint, styles['-error'])}>
                    {error.message}
                </p>
            )}

            {!error && hint && <p className={styles.hint}>{hint}</p>}
        </>
    );
};

export default FileUpload;

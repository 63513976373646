const currencySymbol: { [key: string]: string } = {
    eur: '€',
};

export const formatPrice = (
    amount: number,
    currency: string,
    locale?: string,
) => {
    const postFix = currencySymbol[currency?.toLowerCase()] ?? '';
    const formattedPrice = `${amount?.toFixed(2)}${postFix}`;

    return locale === 'de' ? formattedPrice.replace('.', ',') : formattedPrice;
};

import { atom } from 'recoil';
import { IRebate } from '../shared/models/rebate.interface';

export interface ICouponState {
    redeemed: IRebate[];
}

export const couponState = atom<ICouponState>({
    key: 'coupons',
    default: {
        redeemed: [],
    },
});

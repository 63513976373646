import { IFetcherError, IFetcherResponse } from '../models';

export const post = async (
    url: string,
    dto: any,
): Promise<IFetcherResponse<any>> => {
    const requestOptions = {
        method: 'POST',
        headers: {},
        body: dto,
    };

    const res = await fetch(url, requestOptions);

    if (!res.ok) {
        const error: IFetcherError = new Error(
            'An error occurred while posting the data.',
        );

        error.info = res.statusText;
        error.statusCode = res.status;

        throw error;
    }

    return {
        headers: Object.fromEntries(res.headers.entries()),
        body: await res.blob(),
    };
};

import { fetcher } from '.';
import { IFetcherResponse } from '../models';

export const fetchCategory = async (
    dormNo: number,
    categoryCode: number,
    arrivalDate?: string,
    departureDate?: string,
): Promise<IFetcherResponse<any>> => {
    if (!categoryCode) {
        Promise.reject('no sessionId');
    }

    return fetcher(
        `${process.env.REACT_APP_BOOKING_API_URL}/category?dormNo=${dormNo}&categoryCode=${categoryCode}&arrivalDate=${arrivalDate}&departureDate=${departureDate}`,
    );
};

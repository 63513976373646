import css from 'classnames';
import React from 'react';
import { StatusType } from '../../shared';
import styles from './Tag.module.scss';

interface Props {
    type: StatusType;
    children: JSX.Element | JSX.Element[];
    className?: string;
    filled?: boolean;
    small?: boolean;
}

const Tag: React.FunctionComponent<Props> = ({
    type,
    filled,
    children,
    className,
    small,
}) => {
    return (
        <span
            className={css(styles.tag, className, {
                [styles['-success']]: type === 'success',
                [styles['-warn']]: type === 'warn',
                [styles['-danger']]: type === 'danger',
                [styles['-filled']]: filled,
                [styles['-small']]: small,
            })}
        >
            {children}
        </span>
    );
};

export default Tag;

import { locales } from './locales';

const LOCALE_STORAGE_KEY = 'stuwo-locale';

export const getLocaleFromUrl = (): string | null => {
    const segments = window.location.pathname.split('/').filter((s) => !!s);
    const localeKeys = locales.map((l) => l.key);

    return localeKeys.includes(segments[0]) ? segments[0] : null;
};

export const storeLocale = (locale: string) => {
    if (!locale) {
        return;
    }

    try {
        localStorage.setItem(LOCALE_STORAGE_KEY, locale);
    } catch (error) {
        console.warn('Could not persist locale!', error);
    }
};

export const getStoredLocale = (): string | null => {
    try {
        return localStorage.getItem(LOCALE_STORAGE_KEY);
    } catch (error) {
        console.warn('Could not read locale!', error);
        return null;
    }
};

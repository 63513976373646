import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import { RecoilRoot } from 'recoil';
import App from './App';
import { LoadingPage } from './components';
import { ErrorBoundary } from './error';
import { getLocaleFromUrl, getStoredLocale, I18nRouter } from './i18n';
import reportWebVitals from './reportWebVitals';
import './styles/global.scss';

const urlLocale = getLocaleFromUrl();
const storedLocale = getStoredLocale();

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: storedLocale ?? urlLocale ?? 'de',
        defaultNS: 'common',
        fallbackLng: 'de',
        interpolation: { escapeValue: false }, // React already does escaping
    });

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary isLast>
            <Suspense fallback={<LoadingPage />}>
                <RecoilRoot>
                    <I18nRouter>
                        <App />
                    </I18nRouter>
                </RecoilRoot>
            </Suspense>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

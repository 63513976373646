import { IFetcherError, IFetcherResponse } from '../models';

export const put = async (
    url: string,
    dto: any,
): Promise<IFetcherResponse<any>> => {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dto),
    };

    const res = await fetch(url, requestOptions);

    if (!res.ok) {
        const error: IFetcherError = new Error(
            'An error occurred while putting the data.',
        );

        error.info = res.statusText;
        error.statusCode = res.status;
        error.response = await res.json();

        throw error;
    }
    const contentType = res.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
        return {
            headers: Object.fromEntries(res.headers.entries()),
            body: await res.json(),
        };
    }
    return {
        headers: Object.fromEntries(res.headers.entries()),
        body: res.text(),
    };
};

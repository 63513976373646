import { format, isDate, isValid } from 'date-fns';
import de from 'date-fns/locale/de';

const locales: { [key: string]: any } = {
    en: null, // this is the default
    de,
};

/**
 * Wrapper for date-fns format.
 * It will return a formatted date string in the desired locale.
 *
 * Formats:
 * DE - 'dd. MMMM yyyy'
 * EN - 'MMMM dd, yyyy'
 *
 * Use date-fns format directly if another format is needed.
 */
export const formatDate = (date: Date | string, locale = 'de'): string => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    if (!isDate(date) || !isValid(date)) {
        return '';
    }

    const datePattern: { [key: string]: string } = {
        de: 'dd. MMMM yyyy',
        en: 'MMMM dd, yyyy',
    };

    return format(date, datePattern[locale], { locale: locales[locale] });
};

export const getMonthLabels = (locale = 'de'): string[] => {
    const months = [];
    for (let i = 0; i < 12; i++) {
        months.push(
            format(new Date(2021, i), 'LLLL', { locale: locales[locale] }),
        );
    }

    return months;
};

export const formatDateForApi = (date: string | Date): string => {
    if (!date) {
        return '';
    }

    if (typeof date === 'string') {
        return format(new Date(date), 'yyyy-MM-dd');
    }

    if (!isDate(date) || !isValid(date)) {
        return '';
    }

    return format(date, 'yyyy-MM-dd');
};

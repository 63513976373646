import { atom } from 'recoil';

export type Capacity = 'low' | 'medium' | 'full';

export interface IDorm {
    name: string;
    city: string;
    dormGroup: string;
    dormNo: number;
    email: string;
    offeredCategories: string[];
    postalCode: string;
    street: string;
    petsPermitted: boolean;
    capacity: Capacity;
    waitingListOnly?: boolean;
    phone?: string;
}

export interface IRoomCategory {
    categoryCode: string;
    titleDe: string;
    titleEn: string;
    descriptionDe: string;
    descriptionEn: string;
    dormNo: number;
    movingInFee: number;
    deposit: number;
    priceList: {
        startDate: string;
        endDate: string;
        price: number;
        surcharges: {};
    }[];
    waitingListOnly?: boolean;
    currency: string;
    priceNote: string;
}

interface IAvailableKeys {
    [key: string]: number;
}

export interface IAvailable extends IAvailableKeys {
    any: number;
    diverse: number;
    female: number;
    male: number;
}

export interface ISearchParamsState {
    city: string;
    inDate: string;
    outDate: string;
    dorm?: IDorm;
    room?: IRoomCategory;
    otherDorm?: boolean;
    multipleDormsInCity?: boolean;
    multipleRoomsInDorm?: boolean;
}

export const searchParamsState = atom<ISearchParamsState>({
    key: 'searchParams',
    default: { city: '', inDate: '', outDate: '' },
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch,
    useLocation,
} from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { allowRedirectState } from '../state';
import { getLocaleFromUrl } from './utils';

interface IProps {
    children: React.ReactNode;
}

function I18nRouter({ children }: IProps) {
    const { i18n } = useTranslation('common');

    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Redirect to={`/${i18n.language}`} />
                </Route>

                <Route path="/de">
                    <I18nSync>{children}</I18nSync>
                </Route>
                <Route path="/en">
                    <I18nSync>{children}</I18nSync>
                </Route>

                <Route path="*">
                    <I18nSync>{children}</I18nSync>
                </Route>
            </Switch>
        </Router>
    );
}

function I18nSync({ children }: IProps) {
    const { i18n } = useTranslation('common');
    const { pathname, search } = useLocation();
    const [redirectAllowed, setRedirectAllowed] =
        useRecoilState(allowRedirectState);

    const urlLocaleSegment = getLocaleFromUrl();

    if (!urlLocaleSegment) {
        return <Redirect to={`/${i18n.language}${pathname}${search}`} />;
    }

    if (
        redirectAllowed &&
        urlLocaleSegment &&
        urlLocaleSegment !== i18n.language
    ) {
        setTimeout(() => setRedirectAllowed(false), 100);

        const newLocalePath = window.location.pathname.replace(
            `/${urlLocaleSegment}`,
            `/${i18n.language}`,
        );

        return <Redirect to={`${newLocalePath}${search}`} />;
    }

    return <>{children}</>;
}

export default I18nRouter;

import css from 'classnames';
import { getYear } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getMonthLabels } from '../../shared';
import './DatePicker.module.scss';
import fieldStyles from './DatePicker.module.scss';
import { getDatePickerHeader } from './DatePickerHeader';

interface Props {
    name: string;
    label: string;
    error?: FieldError;
    className?: string;
    onChange?: (value: string) => void;
    minDate?: Date;
    maxDate?: Date;
    defaultValue?: string | undefined;
    disabled?: boolean;
    dateFilter?: (date: Date) => boolean;
    openToDate?: Date;
}

const DatePickerField: React.FunctionComponent<Props> = ({
    name,
    label,
    error,
    className,
    onChange,
    minDate,
    maxDate,
    defaultValue,
    disabled,
    dateFilter,
    openToDate,
}) => {
    const { i18n } = useTranslation('common');
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [hasFocus, setFocus] = useState<boolean>(false);

    const years = useMemo(() => {
        let yearRange: number[] = [];

        const minYear = minDate ? getYear(minDate) : 1930;
        const d = new Date();
        const maxYear = getYear(
            maxDate ?? new Date(d.getFullYear() + 2, d.getMonth(), d.getDate()),
        );

        for (let i = minYear; i <= maxYear; i++) {
            yearRange.push(i);
        }

        return yearRange;
    }, [minDate, maxDate]);

    const months = useMemo(() => {
        return getMonthLabels(i18n.language);
    }, [i18n]);

    const handleOnChange = (date: Date) => {
        setFocus(false);
        setSelectedDate(date);
        onChange && onChange(date?.toISOString());
    };

    useEffect(() => {
        if (defaultValue?.length) {
            setSelectedDate(new Date(defaultValue));
        }
    }, [defaultValue]);

    return (
        <div className={className}>
            {label?.length && (
                <label htmlFor={name} className={fieldStyles.label}>
                    {label}
                </label>
            )}

            <DatePicker
                wrapperClassName={css(fieldStyles.datePicker, {
                    [fieldStyles['-focus']]: hasFocus,
                    [fieldStyles['-error']]: !!error && !hasFocus,
                })}
                selected={selectedDate}
                onChange={handleOnChange}
                minDate={minDate}
                maxDate={maxDate}
                renderCustomHeader={getDatePickerHeader({
                    years,
                    months,
                })}
                disabled={disabled}
                isClearable={!disabled}
                filterDate={dateFilter}
                openToDate={openToDate}
                calendarStartDay={1}
                onFocus={() => setFocus(true)}
                onBlur={() => {
                    setFocus(false);
                }}
                onClickOutside={() => setFocus(false)}
                placeholderText={
                    i18n.language === 'de' ? 'dd.mm.yyyy' : 'dd/mm/yyyy'
                }
                dateFormat={
                    i18n.language === 'de' ? 'dd.MM.yyyy' : 'dd/MM/yyyy'
                }
            />

            {error?.message && (
                <small className={fieldStyles.error}>{error.message}</small>
            )}
        </div>
    );
};

export default DatePickerField;

import useSWR from 'swr';
import { defaultOnErrorRetry } from '../../shared/api';
import { IRoomCategory } from '../../state';

type UseFindPage = {
    inDate: string;
    outDate?: string;
    disableRequest?: boolean;
};
export const useListCategories = ({
    inDate,
    outDate,
    disableRequest,
}: UseFindPage) => {
    const url = `${process.env.REACT_APP_BOOKING_API_URL}/category?arrivalDate=${inDate}&departureDate=${outDate}`;

    const paused = disableRequest || false;

    return useSWR<IRoomCategory[]>(paused ? null : [url], {
        onErrorRetry: defaultOnErrorRetry,
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
    });
};

interface Locale {
    key: string;
    label: string;
}

export const locales: Locale[] = [
    {
        key: 'de',
        label: 'Deutsch',
    },
    {
        key: 'en',
        label: 'English',
    },
];

import de from 'date-fns/locale/de';
import { Suspense, useEffect } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import {
    Route,
    BrowserRouter as Router,
    Switch,
    useRouteMatch,
} from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { LoadingPage } from './components';
import { ErrorBoundary } from './error';
import {
    Checkout,
    Dorm,
    Home,
    Login,
    MembersArea,
    NotFound,
    Password,
    Payment,
    PaymentThanks,
    Room,
    Thanks,
    WaitingListDeregistration,
} from './pages';
import { fetchUserStatus } from './shared/api';
import { gtmState } from './state';
import {
    GTM_TRACKING_ID,
    getStoredSessionId,
    removeStoredSessionId,
} from './utils';

registerLocale('de', de);

interface Props {}

const App: React.FunctionComponent<Props> = () => {
    const { i18n } = useTranslation('common');
    const { path } = useRouteMatch();

    const setGtmScriptLoaded = useSetRecoilState(gtmState);

    // Sets the default locale for all date-pickers
    setDefaultLocale(i18n.language);

    useEffect(() => {
        setInterval(async () => {
            const sessionId = getStoredSessionId();

            if (sessionId) {
                try {
                    await fetchUserStatus(sessionId);
                } catch (error) {
                    console.warn('Could not get status', error);
                    removeStoredSessionId();
                }
            }
        }, 1500000);
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            document.addEventListener('mousedown', () => {
                document.body.classList.remove('-keyboardFocus');
            });

            document.addEventListener('keydown', () => {
                document.body.classList.add('-keyboardFocus');
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getCookie = (name: string) => {
            const value = '; ' + document.cookie;
            const parts = value.split('; ' + name + '=');
            if (parts.length === 2) {
                let cookie;
                const cookieString = parts?.pop()?.split(';').shift();
                if (cookieString) {
                    try {
                        cookie = decodeURIComponent(cookieString);
                        cookie = JSON.parse(cookie);
                    } catch (error) {
                        cookie = null;
                    }
                }
                return cookie;
            }
        };

        const borlabsCookie = getCookie('borlabs-cookie');

        const googleTagManagerAccepted =
            borlabsCookie?.consents?.statistics?.includes('google-tag-manager');

        if (googleTagManagerAccepted && GTM_TRACKING_ID) {
            const existing = document.getElementById('gtm-script');
            if (!existing) {
                const script = document.createElement('script');
                script.defer = true;
                script.id = 'gtm-script';
                script.innerHTML = `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_TRACKING_ID}');
            `;
                document.head.appendChild(script);
            }
            setGtmScriptLoaded(true);
        } else {
            const script = document.getElementById('gtm-script');
            if (script) {
                script.remove();
                setGtmScriptLoaded(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GTM_TRACKING_ID]);

    return (
        <ErrorBoundary>
            <Router>
                <Suspense fallback={<LoadingPage />}>
                    <Switch>
                        <Route exact path={path}>
                            <Home />
                        </Route>
                        <Route path={`${path}/dorm`}>
                            <Dorm />
                        </Route>
                        <Route path={`${path}/room`}>
                            <Room />
                        </Route>
                        <Route path={`${path}/checkout`}>
                            <Checkout />
                        </Route>
                        <Route path={`${path}/thanks`}>
                            <Thanks />
                        </Route>
                        <Route path={`${path}/payment-thanks`}>
                            <PaymentThanks />
                        </Route>
                        <Route path={`${path}/payment`}>
                            <Payment />
                        </Route>
                        <Route path={`${path}/waiting-list-deregistration`}>
                            <WaitingListDeregistration />
                        </Route>
                        <Route path={`${path}/waiting-list-leave`}>
                            <WaitingListDeregistration leave />
                        </Route>
                        <Route path={`${path}/waiting-list-stay`}>
                            <WaitingListDeregistration stay />
                        </Route>
                        <Route path={`${path}/login`}>
                            <Login />
                        </Route>
                        <Route path={`${path}/password`}>
                            <Password />
                        </Route>
                        <Route path={`${path}/members-area`}>
                            <MembersArea />
                        </Route>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </Suspense>
            </Router>
        </ErrorBoundary>
    );
};

export default App;

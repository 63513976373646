import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../components';
import { ReactComponent as ErrorIcon } from '../icons/error-icon.svg';
import styles from './error.module.scss';

interface Props {
    showButton?: boolean;
    noTranslation?: boolean;
}

const ErrorPage: React.FunctionComponent<Props> = ({
    showButton,
    noTranslation,
}) => {
    const { t, i18n } = useTranslation('common');

    const onClick = () => {
        window.location.replace(`/${i18n.language}`);
    };

    return (
        <div className={styles.error}>
            <div className={styles.icon}>
                <ErrorIcon />
            </div>

            <p className={styles.message}>
                {!noTranslation
                    ? t('error.message')
                    : 'Oops! Something went wrong, please try again.'}
            </p>

            {showButton && (
                <Button type="button" onClick={onClick}>
                    {!noTranslation ? t('error.button') : 'Try Again'}
                </Button>
            )}
        </div>
    );
};

export default ErrorPage;

import css from 'classnames';
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { overlayState } from '../../state';
import styles from './Overlay.module.scss';

interface Props {
    children: React.ReactNode;
    className?: string;
    overlayClassName?: string;
    disableClose?: boolean;
}

const Overlay: React.FunctionComponent<Props> = ({
    children,
    className,
    overlayClassName,
    disableClose,
}) => {
    const setOverlayState = useSetRecoilState(overlayState);

    useEffect(() => {
        document.body.classList.add('-noScroll');

        return () => {
            document.body.classList.remove('-noScroll');
        };
    }, []);

    const handleClose = () => {
        setOverlayState(false);
    };

    return (
        <div className={css(styles.overlay, overlayClassName)}>
            <div className={css(styles.modal, className)}>
                {!disableClose && (
                    <button
                        className={styles.modal__close}
                        type="button"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </button>
                )}

                <div className={styles.modal__content}>{children}</div>
            </div>
        </div>
    );
};

export default Overlay;

import { fetcher, post, put } from '.';
import { IFetcherResponse } from '../models';
import { ICreateBooking } from '../models/booking.interface';

export const createBooking = async (
    dto: ICreateBooking | null,
): Promise<IFetcherResponse<any>> => {
    if (!dto) {
        Promise.reject('no data');
    }

    return put(`${process.env.REACT_APP_BOOKING_API_URL}/booking`, dto);
};

export const confirmPayment = async (
    params: URLSearchParams,
): Promise<IFetcherResponse<any>> => {
    if (!params) {
        Promise.reject('no data');
    }

    const response = await post(
        `${process.env.REACT_APP_BOOKING_API_URL}/payment`,
        params,
    );

    // Check if the response body is a Blob
    if (response && response.body instanceof Blob) {
        const blobContent = await response.body.text();

        try {
            const parsedContent = JSON.parse(blobContent);
            return {
                ...response,
                body: parsedContent,
            };
        } catch (e) {
            console.error('Failed to parse blob content:', e);
            return {
                ...response,
                body: blobContent,
            };
        }
    }

    return response;
};

export const getOffer = async (id: string): Promise<IFetcherResponse<any>> => {
    if (!id) {
        Promise.reject('no data');
    }

    return fetcher(
        `${process.env.REACT_APP_BOOKING_API_URL}/offer?id=${id}&method=POST`,
    );
};

const dormsPerCity: { [key: string]: string } = {
    1: 'wien',
    3: 'wien',
    4: 'wien',
    6: 'wien',
    7: 'wien',
    9: 'wien',
    10: 'wien',
    11: 'villach',
    12: 'wien',
    13: 'wien',
    15: 'krems',
    16: 'linz',
    17: 'lambach',
    19: 'graz',
    20: 'graz',
    21: 'wien',
    22: 'wien',
    23: 'innsbruck',
    24: 'wien',
    25: 'wien',
};

export const getCityByDormNo = (dormNo: string): string => {
    if (!dormNo) return '';

    return dormsPerCity[dormNo];
};

import css from 'classnames';
import React from 'react';
import styles from './Card.module.scss';

interface Props {
    children: React.ReactNode;
    className?: string;
}

const Card: React.FunctionComponent<Props> = ({ children, className }) => {
    return <div className={css(styles.card, className)}>{children}</div>;
};

export default Card;

import { fetcher, post } from '.';
import { IFetcherResponse } from '../models';

export const uploadDocument = async (
    dto: any,
): Promise<IFetcherResponse<any>> => {
    if (!dto) {
        Promise.reject('no data');
    }

    return post(`${process.env.REACT_APP_BOOKING_API_URL}/document`, dto);
};

export const fetchContract = async (
    dto: any,
): Promise<IFetcherResponse<any>> => {
    if (!dto) {
        Promise.reject('no data');
    }

    const arrivalDate =
        dto?.arrivalDate &&
        new Date(dto.arrivalDate as string)?.toISOString()?.slice(0, 10);

    const departureDate =
        dto?.departureDate &&
        new Date(dto.departureDate as string)?.toISOString()?.slice(0, 10);

    return fetcher(
        `${process.env.REACT_APP_BOOKING_API_URL}/contract?lastName=${
            dto.lastName
        }&firstName=${dto.firstName}&birthDate=${dto.birthDate}&country=${
            dto.country
        }&postalCode=${dto.postalCode}&city=${dto.city}&street=${dto.street}${
            dto?.address2 && `&address2=${dto.address2}`
        }&email=${dto.email}${
            dto?.petDescription && `&petDescription=${dto.petDescription}`
        }&dormNo=${dto.dormNo}&categoryCode=${
            dto.categoryCode
        }&arrivalDate=${arrivalDate}&departureDate=${departureDate}${
            dto?.rebateCode && `&rebateCode=${dto.rebateCode}`
        }`,
    );
};

export const fetchUserContract = async (
    dto: any,
): Promise<IFetcherResponse<any>> => {
    if (!dto) {
        Promise.reject('no data');
    }

    const arrivalDate =
        dto?.arrivalDate &&
        new Date(dto.arrivalDate as string)?.toISOString()?.slice(0, 10);

    return fetcher(
        `${process.env.REACT_APP_BOOKING_API_URL}/contract?personNo=${dto.personNo}&lastName=${dto.lastName}&firstName=${dto.firstName}&dormNo=${dto.dormNo}&categoryCode=${dto.categoryCode}&arrivalDate=${arrivalDate}`,
    );
};

import { fetcher } from '.';
import { IFetcherResponse } from '../models';

export const fetchDorm = async (
    dormNo: number,
): Promise<IFetcherResponse<any>> => {
    if (!dormNo) {
        Promise.reject('no sessionId');
    }

    return fetcher(
        `${process.env.REACT_APP_BOOKING_API_URL}/dorm?dormNo=${dormNo}`,
    );
};

import css from 'classnames';
import React from 'react';
import styles from './Button.module.scss';

interface Props {
    type: 'button' | 'submit' | 'reset';
    children: string | JSX.Element | JSX.Element[];
    theme?: 'primary' | 'secondary' | 'grey' | 'darkgrey';
    onClick?: () => void;
    className?: string;
    outlined?: boolean;
    small?: boolean;
    disabled?: boolean;
}

const Button: React.FunctionComponent<Props> = ({
    type,
    children,
    onClick,
    className,
    outlined,
    theme,
    small,
    disabled,
}) => {
    theme = theme ?? 'primary';

    const handleOnClick = () => {
        onClick && onClick();
    };

    return (
        <button
            type={type}
            className={css(styles.button, className, {
                [styles['-outlined']]: outlined,
                [styles['-primary']]: theme === 'primary',
                [styles['-secondary']]: theme === 'secondary',
                [styles['-grey']]: theme === 'grey',
                [styles['-darkgrey']]: theme === 'darkgrey',
                [styles['-small']]: small,
            })}
            onClick={handleOnClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;

import css from 'classnames';
import { addMonths, lastDayOfMonth, startOfMonth } from 'date-fns';
import addYears from 'date-fns/addYears';
import endOfYear from 'date-fns/endOfYear';
import getMonth from 'date-fns/getMonth';
import isBefore from 'date-fns/isBefore';
import subMonths from 'date-fns/subMonths';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { SelectField } from '../../form';
import { formatDate, formatDateForApi } from '../../shared';
import { ISearchParamsState, searchParamsState } from '../../state';
import styles from './SearchForm.module.scss';
import { useListCategories } from './useListCategories';

interface Props {
    onSubmit: (formData: ISearchParamsState) => void;
    children: JSX.Element;
    className?: string;
    disabled?: boolean;
}

const SearchForm: React.FunctionComponent<Props> = ({
    onSubmit,
    children,
    className,
    disabled,
}) => {
    const { t, i18n } = useTranslation('common');
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        formState: { errors },
    } = useForm<ISearchParamsState>();
    const [formData, setFormData] = useRecoilState(searchParamsState);
    const [inDates, setInDates] = useState<string[]>([]);
    const [endOfMonthArray, setEndOfMonthArray] = useState<string[]>([]);
    const [endOfMonth31Array, setEndOfMonth31Array] = useState<string[]>([]);
    const [lastOutDate, setLastOutDate] = useState<string>('');

    const {
        data: rooms,
        error: roomsError,
        isValidating,
    } = useListCategories({
        inDate: formatDateForApi(new Date()),
        outDate: formatDateForApi(endOfYear(addYears(new Date(), 3))),
    });

    useEffect(() => {
        if (isValidating && !rooms?.length) {
            return;
        }

        const validPriceLists = rooms
            ?.filter((room) => room.dormNo !== 14)
            ?.map((room) => {
                return room.priceList?.filter((price) => price.price);
            })
            .flat()
            .map((price) => price.endDate);

        let highestEndDate;

        if (!validPriceLists?.length) {
            return;
        }

        try {
            highestEndDate = new Date(
                Math.max(
                    ...(validPriceLists ?? []).map((date) =>
                        new Date(date).getTime(),
                    ),
                ),
            );
            if (highestEndDate) {
                setLastOutDate(formatDateForApi(highestEndDate));
            }
        } catch (error) {
            console.error(error);
        }
    }, [rooms, isValidating]);

    const inDateWatch = useWatch({ control, name: 'inDate' });

    useEffect(() => {
        if (formData?.inDate && !getValues('inDate')) {
            setValue('inDate', formData.inDate);
        }
        if (getValues('inDate')) {
            setValue('inDate', getValues('inDate'));
        }
        if (formData?.outDate) {
            setValue('outDate', formData.outDate);
        }

        if (getValues('inDate') || formData.inDate) {
            createAllowedOutDates(getValues('inDate') || formData.inDate);
        }
        if (
            formData.inDate &&
            (!endOfMonth31Array.length || !endOfMonthArray.length)
        ) {
            createAllowedOutDates(formData.inDate);
        }
        if (!getValues('inDate') && !formData.inDate) {
            setDefaultInDate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        inDateWatch,
        endOfMonth31Array.length,
        endOfMonthArray.length,
        formData.inDate,
        formData.outDate,
        inDates,
    ]);

    useEffect(() => {
        createAllowedInDates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastOutDate]);

    const setDefaultInDate = () => {
        let monthsUntilSeptember = 8 - getMonth(new Date());

        const date = getFirstOfMonthNMonthsAfterToday(monthsUntilSeptember);

        if (inDates?.includes(date)) {
            setValue('inDate', date);
        } else {
            setValue('inDate', inDates[0]);
        }
    };

    useEffect(() => {
        // set default outdate
        if (endOfMonth31Array.length && formData.outDate) {
            if (
                endOfMonth31Array.includes(formData.outDate) ||
                endOfMonthArray.includes(formData.outDate)
            ) {
                setValue('outDate', formData.outDate);
            }
        } else if (getValues('outDate') !== endOfMonth31Array?.[0]) {
            setValue('outDate', formatDateForApi(endOfMonth31Array?.[0]));
        }
    }, [endOfMonth31Array, endOfMonthArray]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFormValid: SubmitHandler<ISearchParamsState> = (data) => {
        if (!data?.city || !data?.inDate || !data?.outDate) {
            setFormData(formData);
        } else {
            setFormData(data);
        }
        onSubmit(formData);
    };

    const getFirstOfMonthNMonthsAfterToday = (
        monthsInFuture: number,
    ): string => {
        const date = startOfMonth(addMonths(new Date(), monthsInFuture));
        return formatDateForApi(date);
    };

    const createAllowedInDates = (): void => {
        const today = new Date();
        const nextMonth = addMonths(today, 1);
        const endOfPeriod = subMonths(new Date(lastOutDate), 1);

        const dates = [];

        let currentDate = startOfMonth(nextMonth);

        while (isBefore(currentDate, endOfPeriod)) {
            dates.push(formatDateForApi(currentDate));
            currentDate = addMonths(currentDate, 1);
        }

        setInDates(dates);
    };

    const createAllowedOutDates = (inDateString: string): void => {
        const selectedStartDate = new Date(inDateString);
        const endOfMonthDates = [];
        const endOfMonth31Dates = [];

        const startDate = addMonths(selectedStartDate, 0);
        const endDate = new Date(lastOutDate);

        let currentDate = startDate;

        while (isBefore(currentDate, endDate)) {
            const currentMonth = getMonth(currentDate);

            if (currentMonth === 7) {
                endOfMonth31Dates.push(
                    formatDateForApi(lastDayOfMonth(currentDate)),
                );
            } else {
                endOfMonthDates.push(
                    formatDateForApi(lastDayOfMonth(currentDate)),
                );
            }

            currentDate = addMonths(currentDate, 1);
        }

        setEndOfMonthArray(endOfMonthDates);
        setEndOfMonth31Array(endOfMonth31Dates);
    };

    return (
        <>
            <form
                className={css(styles.searchForm, className, {
                    [styles['-error']]: !!Object.keys(errors)?.length,
                })}
                onSubmit={handleSubmit(onFormValid)}
                noValidate
            >
                <div className={css(styles.searchForm__fields)}>
                    <SelectField
                        name="city"
                        label={t('searchForm.city.label')}
                        register={register}
                        registerOptions={{
                            required: {
                                value: true,
                                message: t('searchForm.formError.city'),
                            },
                            minLength: 1,
                        }}
                        defaultValue={formData.city}
                        disabled={disabled}
                        error={errors.city}
                    >
                        <option></option>
                        <option value="Wien">
                            {t('searchForm.cities.vienna')}
                        </option>
                        <option value="Graz">
                            {t('searchForm.cities.graz')}
                        </option>
                        <option value="Innsbruck">
                            {t('searchForm.cities.innsbruck')}
                        </option>
                        <option value="Krems">
                            {t('searchForm.cities.krems')}
                        </option>
                        <option value="Lambach">
                            {t('searchForm.cities.lambach')}
                        </option>
                        <option value="Villach-St. Magdalen">
                            {t('searchForm.cities.villach')}
                        </option>
                        <option value="Linz">
                            {t('searchForm.cities.linz')}
                        </option>
                    </SelectField>

                    <SelectField
                        name="inDate"
                        label={t('searchForm.inDate.label')}
                        register={register}
                        registerOptions={{
                            required: {
                                value: true,
                                message: t('searchForm.formError.inDate'),
                            },
                            minLength: 1,
                        }}
                        defaultValue={formData.inDate}
                        disabled={disabled}
                        error={errors.inDate}
                    >
                        <option></option>
                        <>
                            {inDates?.map((date: string, i) => (
                                <option value={date} key={i}>
                                    {formatDate(new Date(date), i18n.language)}
                                </option>
                            ))}
                        </>
                    </SelectField>

                    <SelectField
                        name="outDate"
                        label={t('searchForm.outDate.label')}
                        register={register}
                        registerOptions={{
                            required: {
                                value: true,
                                message: t('searchForm.formError.outDate'),
                            },
                            minLength: 1,
                        }}
                        defaultValue={formData.outDate}
                        disabled={
                            disabled ||
                            (!endOfMonth31Array?.length &&
                                !endOfMonthArray?.length)
                        }
                        error={errors.outDate}
                    >
                        <option></option>
                        <>
                            {endOfMonth31Array?.map((date: string, i) => (
                                <option value={date} key={i}>
                                    {formatDate(new Date(date), i18n.language)}
                                </option>
                            ))}
                            {endOfMonthArray?.length && (
                                <optgroup label="-------------------------" />
                            )}
                            {endOfMonthArray?.map((date: string, i) => (
                                <option value={date} key={i}>
                                    {formatDate(new Date(date), i18n.language)}
                                </option>
                            ))}
                        </>
                    </SelectField>
                </div>

                {children}
            </form>
            {!!roomsError && (
                <small className={styles.error}>Error fetching rooms</small>
            )}
        </>
    );
};

export default SearchForm;

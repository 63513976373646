import css from 'classnames';
import React, { useState } from 'react';
import {
    FieldError,
    RegisterOptions,
    UseFormRegister,
    UseFormRegisterReturn,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShowPasswordIcon } from '../../icons/visible-icon.svg';
import fieldStyles from '../FormField.module.scss';

interface Props {
    name: string;
    label: string;
    type: string;
    error?: FieldError;
    className?: string;
    register?: UseFormRegister<any>;
    registerOptions?: RegisterOptions;
    onClick?: () => void;
    onChange?: (value: string) => void;
    defaultValue?: string | undefined;
    showPasswordIcon?: boolean;
    onlyNumbers?: boolean;
}

const InputField: React.FunctionComponent<Props> = ({
    name,
    label,
    type,
    error,
    className,
    register,
    registerOptions,
    onClick,
    onChange,
    defaultValue,
    showPasswordIcon,
    onlyNumbers,
}) => {
    const { t } = useTranslation('common');
    let useFormProps: UseFormRegisterReturn | null = null;
    const [hasFocus, setFocus] = useState<boolean>(false);
    const [overrideType, setOverrideType] = useState<string>(type);

    if (register) {
        if (type === 'email') {
            registerOptions = {
                ...registerOptions,
                pattern: {
                    // eslint-disable-next-line no-useless-escape
                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: t('emailError'),
                },
            };
        }
        if (onlyNumbers) {
            registerOptions = {
                ...registerOptions,
                pattern: {
                    value: /^[0-9-]*$/,
                    message: t('checkout.formError.postalCode'),
                },
            };
        }

        useFormProps = register(name, Object.assign({}, registerOptions));
    }

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setFocus(false);
        useFormProps && useFormProps.onBlur(event);
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event.target.value);
        useFormProps && useFormProps.onChange(event);
    };

    const toggleType = () => {
        if (overrideType === 'password') {
            setOverrideType('text');
        } else {
            setOverrideType('password');
        }
    };

    const nameAttr = useFormProps ? useFormProps.name : name;

    return (
        <div className={className} onClick={onClick}>
            {label?.length && (
                <label htmlFor={nameAttr} className={fieldStyles.label}>
                    {label}
                </label>
            )}

            <div
                className={css(fieldStyles.formField, {
                    [fieldStyles['-focus']]: hasFocus,
                    [fieldStyles['-error']]: !!error && !hasFocus,
                })}
            >
                <input
                    ref={useFormProps?.ref}
                    name={nameAttr}
                    type={overrideType}
                    onFocus={() => setFocus(true)}
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                    className={fieldStyles.control}
                    defaultValue={defaultValue}
                />
                {showPasswordIcon && (
                    <button
                        type="button"
                        onClick={toggleType}
                        className={fieldStyles.visibleIcon}
                    >
                        <ShowPasswordIcon />
                    </button>
                )}
            </div>

            {error?.message && (
                <small className={fieldStyles.error}>{error.message}</small>
            )}
        </div>
    );
};

export default InputField;

import { put } from '.';
import { ICheckoutFormData } from '../../state';
import { IFetcherResponse } from '../models';

export const createPerson = async (
    dto: ICheckoutFormData | null,
): Promise<IFetcherResponse<any>> => {
    if (!dto) {
        Promise.reject('no data');
    }

    const birthDate = new Date(dto?.birthDate as string)
        .toISOString()
        .slice(0, 10);

    return put(`${process.env.REACT_APP_BOOKING_API_URL}/person`, {
        ...dto,
        birthDate,
    });
};

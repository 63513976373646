import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { Button, SearchForm } from '..';
import { SelectField } from '../../form';
import { getLocaleFromUrl, locales, storeLocale } from '../../i18n';
import { ReactComponent as AccountFill } from '../../icons/account-fill.svg';
import { ReactComponent as LanguageIcon } from '../../icons/language.svg';
import {
    allowRedirectState,
    overlayState,
    searchParamsState,
} from '../../state';
import { getStoredSessionId, removeStoredCheckoutState } from '../../utils';
import styles from './Header.module.scss';

interface Props {
    showForm?: boolean;
    hideLogin?: boolean;
    hideAccount?: boolean;
    clearFormOnNavigate?: boolean;
}

const Header: React.FunctionComponent<Props> = ({
    showForm,
    hideLogin,
    hideAccount,
    clearFormOnNavigate,
}) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const { t, i18n } = useTranslation('common');

    const setOverlayState = useSetRecoilState(overlayState);
    const resetSearchParams = useResetRecoilState(searchParamsState);
    const setRedirectAllowed = useSetRecoilState(allowRedirectState);
    const urlLocaleSegment = getLocaleFromUrl();

    const sessionId = getStoredSessionId();

    useEffect(() => {
        if (document?.documentElement?.lang !== i18n.language) {
            handleLocaleChange(i18n.language);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document?.documentElement?.lang]);

    const handleLocaleChange = (locale: string) => {
        document.documentElement.lang = locale;
        i18n.changeLanguage(locale);
        storeLocale(locale);
        setRedirectAllowed(true);
    };

    const clearSearchState = () => {
        resetSearchParams();
    };

    const navigateHome = async (remove?: boolean) => {
        if (clearFormOnNavigate) {
            clearSearchState();
        }
        if (remove) {
            removeStoredCheckoutState();
        }
        setOverlayState(false);
        history.push(`/${i18n.language}`);
    };

    const goHome = () => {
        if (clearFormOnNavigate) {
            clearSearchState();
        }
        setTimeout(() => {
            navigateHome();
        });
    };

    const goStuwo = () => {
        window.location.href = `https://stuwo.at/${
            i18n.language === 'de' ? '' : i18n.language
        }`;
    };

    useEffect(() => {
        if (urlLocaleSegment && urlLocaleSegment !== i18n.language) {
            i18n.changeLanguage(urlLocaleSegment);
            storeLocale(urlLocaleSegment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <>
            <header className={styles.header}>
                <div className={styles.header__content}>
                    <button
                        tabIndex={0}
                        onClick={goStuwo}
                        className={styles.header__logo}
                    >
                        <img
                            className={styles.logo}
                            src="/logo-claim-black.png"
                            alt="STUWO Student Housing"
                        />
                    </button>

                    {showForm && (
                        <div className={styles.formBox}>
                            <SearchForm onSubmit={goHome} disabled>
                                <Button
                                    type="submit"
                                    className={styles.formBox__submit}
                                >
                                    {t('searchForm.submitChange')}
                                </Button>
                            </SearchForm>
                        </div>
                    )}

                    <div className={styles.nav}>
                        <nav className={styles.menu}>
                            {!sessionId && !hideLogin && (
                                <Link
                                    to={`/${i18n.language}/login`}
                                    className={styles.menu__item}
                                >
                                    <span className={styles.menu__account}>
                                        <AccountFill /> Login
                                    </span>
                                </Link>
                            )}
                            {!!sessionId && !hideAccount && (
                                <Link
                                    to={`/${i18n.language}/members-area`}
                                    className={styles.menu__item}
                                >
                                    <span className={styles.menu__account}>
                                        <AccountFill /> Login
                                    </span>
                                </Link>
                            )}
                        </nav>

                        <form className={styles.form}>
                            <SelectField
                                name="language"
                                className={styles.language}
                                value={i18n.language}
                                onChange={handleLocaleChange}
                            >
                                {locales.map((locale) => (
                                    <option key={locale.key} value={locale.key}>
                                        {locale.label}
                                    </option>
                                ))}
                            </SelectField>
                            <span className={styles.langIcon}>
                                <LanguageIcon />
                            </span>
                        </form>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;

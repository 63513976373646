import { fetcher } from '.';
import { IFetcherResponse } from '../models';
import { IGetRebate, IRebate } from '../models/rebate.interface';

export const getRebates = async (
    dto: IGetRebate | null,
): Promise<IFetcherResponse<IRebate[]>> => {
    if (!dto) {
        Promise.reject('no data');
    }

    const arrivalDate = new Date(dto?.arrivalDate as string)
        ?.toISOString()
        ?.slice(0, 10);

    return fetcher(
        `${process.env.REACT_APP_BOOKING_API_URL}/rebate?dormNo=${dto?.dormNo}&arrivalDate=${arrivalDate}`,
    );
};

import { ICheckoutFormData, ICouponState, ISearchParamsState } from '../state';

const PERSON_ID = 'person-id';
const SESSION_ID = 'session-id';
const BOOKING_DATA = 'booking-data';
const CHECKOUT_STATE = 'checkout-state';

export const storePerson = (personId: string) => {
    if (!personId) {
        return;
    }

    try {
        localStorage.setItem(PERSON_ID, personId);
    } catch (error) {
        console.warn('Could not persist person!', error);
    }
};

export const getStoredPerson = (): string | null => {
    try {
        return localStorage.getItem(PERSON_ID);
    } catch (error) {
        console.warn('Could not read person!', error);
        return null;
    }
};

export const removeStoredPerson = () => {
    try {
        localStorage.removeItem(PERSON_ID);
    } catch (error) {
        console.warn('Could not remove person!', error);
    }
};

export const storeSessionId = (sessionId: string) => {
    if (!sessionId) {
        return;
    }

    try {
        localStorage.setItem(SESSION_ID, sessionId);
    } catch (error) {
        console.warn('Could not persist session id!', error);
    }
};

export const getStoredSessionId = (): string | null => {
    try {
        return localStorage.getItem(SESSION_ID);
    } catch (error) {
        console.warn('Could not read session id!', error);
        return null;
    }
};

export const removeStoredSessionId = () => {
    try {
        localStorage.removeItem(SESSION_ID);
    } catch (error) {
        console.warn('Could not remove session id!', error);
    }
};

export const storeBookingData = (bookingData: string) => {
    if (!bookingData) {
        return;
    }

    try {
        localStorage.setItem(BOOKING_DATA, bookingData);
    } catch (error) {
        console.warn('Could not persist booking data!', error);
    }
};

export const getStoredBookingData = (): any | null => {
    try {
        const data = localStorage.getItem(BOOKING_DATA);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    } catch (error) {
        console.warn('Could not read booking data!', error);
        return null;
    }
};

export const removeStoredBookingData = () => {
    try {
        localStorage.removeItem(BOOKING_DATA);
    } catch (error) {
        console.warn('Could not remove booking data!', error);
    }
};

export const storeCheckoutState = (state: {
    searchParams: ISearchParamsState;
    checkoutForm: ICheckoutFormData;
    coupons?: ICouponState;
}) => {
    if (!state) {
        return;
    }

    try {
        localStorage.setItem(CHECKOUT_STATE, JSON.stringify(state));
    } catch (error) {
        console.warn('Could not persist checkout state!', error);
    }
};

export const getStoredCheckoutState = (): {
    searchParams: ISearchParamsState;
    checkoutForm: ICheckoutFormData;
    coupons: ICouponState;
} | null => {
    try {
        const data = localStorage.getItem(CHECKOUT_STATE);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    } catch (error) {
        console.warn('Could not read stored checkout state!', error);
        return null;
    }
};

export const removeStoredCheckoutState = () => {
    try {
        localStorage.removeItem(CHECKOUT_STATE);
    } catch (error) {
        console.warn('Could not remove stored checkout state!', error);
    }
};
